<template>
  <dx-util-popup
    ref="bulkReservationPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="false"
    width="auto"
    height="auto"
    :position="'center'"
    @shown="popupShown"
    @hidden="popupHidden"
  >
    <div>
      <div class="alert alert-warning">
        The items listed below have missing reservation.
        Please match the correct items from your inventory.
      </div>
      <div>
        <div class="">
          <div class="d-flex align-items-start">
            <div role="button" @click="enlargeImage(firstItem.largeImage)">
              <img :src="firstItem.smallImage || require('@/assets/images/undraw/no_images.svg')" style="height: 80px !important; width: 80px !important; margin-right: 0.625rem; margin-top: 0.425rem;" class="rounded text-white">
            </div>
            <div>
              <div class="text-warning font-weight-bold">
                Order IDs: <strong> {{ orderIdsStr }} </strong>
              </div>
              <div>
                {{ firstItem.productTitle }}
              </div>
              <small class="d-block">
                SKU: {{ firstItem.sku }}
              </small>
              <small>
                ASIN: {{ firstItem.asin }}
              </small>
            </div>
            <div class="flex-grow-1"></div>
            <div>
              <dx-util-button type="default" icon="icon bi-search" text="Find Item" @click="openFBMInventory" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-right mt-1">
      <dx-util-button text="Cancel" type="danger" style="width:100px" class="mr-1" @click="closePopup" />
      <dx-util-button text="Save" type="success" style="width:100px" @click="handleSubmit" />
    </div>
    <div>
      <light-box
        :items="images"
        :index="index"
        :effect="'fade'"
        @close="index = null"
      />
    </div>
    <fbm-inventory-search :component-id="fbmInventorySearchCompId" :search-data="searchData" :is-bulk="true" @emit-item-selected="updateItemSelection" />
  </dx-util-popup>
</template>

<script>
// import { Notify } from '@/@robustshell/utils'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import fbmService from '@/http/requests/fbm/fbmService'

export default {
  components: {
    'light-box': CoolLightBox,
    'fbm-inventory-search': () => import('./FbmInventorySearch.vue'),
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      popupTitle: '',
      images: [],
      index: null,
      fbmInventorySearchCompId: '',
      selectedProduct: {},
      searchData: {
        productName: '',
        storeId: null,
        fbmOrderItemId: null,
        orderSku: null,
        orderId: null,
        isAdditionalItem: false,
        orderQuantity: 0,
      },
      reservations: [],
    }
  },
  computed: {
    bulkReservationPopup() {
      return this.$refs.bulkReservationPopupRef.instance
    },
    orderIdsStr() {
      return this.items.map(el => el.orderNumber)?.toString()
    },
    firstItem() {
      return this.items[0]
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.bulkReservationPopup.show()
      },
    },
  },
  methods: {
    popupShown() {
      this.popupTitle = 'Reservation / Product Matching'
    },
    popupHidden() {
      // Clear form when popup is closed
    },
    closePopup() {
      this.bulkReservationPopup.hide()
    },
    async handleSubmit() {
      await fbmService.makeBulkReservation(this.reservations)
      this.$emit('emit-form-saved')
      this.closePopup()
    },
    enlargeImage(imageUrl) {
      if (imageUrl === '' || imageUrl === null || imageUrl === undefined) return
      this.images.length = 0
      this.index = 0
      this.images.push(imageUrl)
    },
    openFBMInventory() {
      this.searchData.orderSku = this.firstItem.sku
      this.searchData.productName = this.firstItem.productTitle
      this.searchData.storeId = this.firstItem.storeId
      this.fbmInventorySearchCompId = this.$uid()
    },
    updateItemSelection(product) {
      this.selectedProduct = product

      this.items.map(el => el.fbmOrderItemId).forEach(id => {
        const payload = {
          fbmOrderItemId: id,
          productId: this.selectedProduct.id,
        }

        // Find the index of the existing object with the same fbmOrderItemId
        const index = this.reservations.findIndex(
          reservation => reservation.fbmOrderItemId === id,
        )

        if (index !== -1) {
          // Replace the existing object
          this.reservations[index] = payload
        } else {
          // Add new object if it doesn't exist
          this.reservations.push(payload)
        }
      })
    },

  },
}
</script>

<style lang="scss" scoped>

</style>
